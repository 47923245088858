import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import AnchorPrismic from "../components/elements/AnchorPrismic";
import { constructActiveDoc } from "../helpers";

import "../styles/pages/contact.scss";

const Contact = ({ data }) => {
  useEffect(() => {
    document.getElementById("contact_form").innerHTML = "";
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "9215795",
        formId:
          data.page.data.hubspot_form_id ||
          "60cf33a8-3359-47d2-b34f-a87b0744cef4",
        target: "#contact_form",
        onFormSubmitted: () => {
          document.querySelector(".contact-page__content").style.display =
            "none";
        },
      });

      document.querySelector("#contact_form").addEventListener("input", (e) => {
        if (e.target.nodeName !== "INPUT") return null;

        if (e.target.value !== "") {
          e.target.closest(".hs-form-field").classList.add("has-value");
        } else {
          e.target.closest(".hs-form-field").classList.remove("has-value");
        }

        return null;
      });
    });
  }, []);

  if (!data) return null;

  const { settings, page } = data;
  const { title, image, image_mobile: imageMobile, content } = page.data;

  return (
    <>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.6.0.min.js"
          integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
          crossOrigin="anonymous"
        />
      </Helmet>
      <Seo page={page} settings={settings} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <article className="contact-page">
          <HeaderBackground
            title={title}
            image={image}
            imageMobile={imageMobile}
            headerSize="2"
          />
          <Container as="section" className="py-6" fluid>
            <Row>
              <Col
                xl={{ span: 10, offset: 1 }}
                className="col-xxxl-6 offset-xxxl-3"
              >
                <div className="contact-page__content">
                  <RichText
                    render={content.richText}
                    serializeHyperlink={AnchorPrismic}
                  />
                </div>
                <div className="mt-5 mt-md-6" id="contact_form" />
              </Col>
            </Row>
          </Container>
        </article>
      </Layout>
    </>
  );
};

export const query = graphql`
  query contacQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicContac(lang: { eq: $lang }) {
      ...prismicContacFragment
    }
  }
`;

export default withPrismicPreview(Contact);
